import React from 'react';
import style from './index.module.scss';
import coreml_image from './assets/coreml_icon.png';

class Logo extends React.Component {
  render() {
    return (
      <div className={style.Logo}>
          <img src={coreml_image}
               width={70}
               height={78}/>
      </div>
    );
  }
}

export default Logo;