import React from 'react';
import style from './index.module.scss';

class EmailList extends React.Component {
  render() {
    return (
      <div className={style.EmailList}>
        <div className={style.Title}>
          FOLLOW OUR <span className={style.TitleEmphasis}>UPDATES</span>
        </div>
        <div className={style.EmailInputBox}>
          <input className={style.EmailInput}
                 type={"text"} 
                 placeholder={"EMAIL"}/>
        </div>
        <div className={style.EmailInputBox}>
          <button className={style.EmailButton}>
            SIGN UP
          </button>
        </div>
      </div>
    );
  }
}

export default EmailList;