import React from 'react';
import style from './index.module.scss';

class TitleBar extends React.Component {
  render() {
    return (
      <div className={style.Title}>
        <div className={style.TitleText}>
          A CURATED LIST OF <span className={style.TitleHighlight}> MLMODELS </span>
        </div>
      </div>
    );
  }
}

export default TitleBar;