export default class ModelTemplate {
	title() {
		console.error("Unimplemented Error: title");
	}
	size() {
		console.error("Unimplemented Error: size");
	}
	description() {
		console.error("Unimplemented Error: description");
	}
	downloadURL() {
		console.error("Unimplemented Error: downloadURL");
	}
	type(){
		/*1, 2, 3 replace with enums later*/
		console.error("Unimplemented Error: type");
	}
}