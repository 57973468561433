import ModelTemplate from "../Templates/ModelTemplate";

export default class TinyYolo extends ModelTemplate {
	title() {
		return "Tiny Yolo";
	}
	
	size() {
		return "60.5 MB";
	}
	
	description() {
		return " Recognize what the objects are inside a given image and where they are in the image.";
	}

	downloadURL() {
		return "https://github.com/hollance/YOLO-CoreML-MPSNNGraph/raw/master/TinyYOLO-CoreML/TinyYOLO-CoreML/TinyYOLO.mlmodel"
	}

	type(){
		/*1, 2, 3 replace with enums later*/
		return 1
	}
}