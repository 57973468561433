import React from 'react';
import style from './index.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faImage } from '@fortawesome/free-solid-svg-icons'
import { faParagraph } from '@fortawesome/free-solid-svg-icons'
import { faMicrophone } from '@fortawesome/free-solid-svg-icons'

class Filter extends React.Component {
  clickImage(value) {
    if (this.props.filter == value) {
      this.props.changeFilter(null);
    } else {
      this.props.changeFilter(value);
    }
  }

  render() {
    const styleImage = (this.props.filter == 1)? style.FilterElement + " " + style.FilterElementActive:style.FilterElement;
    const styleParagraph = (this.props.filter == 2)? style.FilterElement + " " + style.FilterElementActive:style.FilterElement;
    const styleMicrophone = (this.props.filter == 3)? style.FilterElement + " " + style.FilterElementActive:style.FilterElement;

    return (
      <div className={style.Filter}>
        <div className={styleImage} onClick={this.clickImage.bind(this, 1)}>
          <FontAwesomeIcon icon={faImage} />
        </div>
        <div className={styleParagraph} onClick={this.clickImage.bind(this, 2)}>
          <FontAwesomeIcon icon={faParagraph} />
        </div>
        <div className={styleMicrophone} onClick={this.clickImage.bind(this, 3)}>
          <FontAwesomeIcon icon={faMicrophone} />
        </div>
      </div>
    );
  }
}

export default Filter;