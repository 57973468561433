import React from 'react';
import style from './index.module.scss';

class Feedback extends React.Component {
  render() {
    return (
      <div className={style.Feedback}>
        <div className={style.Title}>
          HOW CAN WE MAKE OUR SITE <span className={style.TitleEmphasis}>BETTER?</span>
        </div>
        <div className={style.TextAreaContainer}>
          <textarea className={style.TextArea} placeholder={"I’d like to try the models before I download them... The site is perfect!"}>
          </textarea>
        </div>
        <div className={style.TextAreaContainer}>
          <button className={style.TextAreaButton}>
            SUBMIT
          </button>
        </div>
      </div>
    );
  }
}

export default Feedback;