import React from 'react';
import logo from './logo.svg';
import './App.css';

import EmailList from './Components/EmailList';
import Feedback from './Components/Feedback';
import Filter from './Components/Filter';
import Logo from './Components/Logo';
import Model from './Components/Model';
import ModelContainer from './Components/ModelContainer';
import TitleBar from './Components/TitleBar';

import { Models } from "./Components/registry"

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: null
    }
  }

  changeFilter(value) {
    this.setState({
      filter: value
    });
  }

  modelList() {
    const self = this
    const FilteredModels = Models;
    const filt = FilteredModels.filter(model => (!self.state.filter)?true:(self.state.filter == model.type())?true:false);
    return filt.map((model)=> {
      return (<Model model={model}/>)
    });
  }
  render() {
    return (
      <div className="App">
        <Logo />
        <EmailList />
        <TitleBar />
        <Filter changeFilter={this.changeFilter.bind(this)}
                filter={this.state.filter}/>
        <ModelContainer models={this.modelList()} />
        <Feedback />
      </div>
    );
  }
}

export default App;
