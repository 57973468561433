import React from 'react';
import style from './index.module.scss';

class ModelContainer extends React.Component {
  render() {
    return (
      <div className={style.ModelContainer}>
        {this.props.models}
      </div>
    );
  }
}

export default ModelContainer;