import React from 'react';
import style from './index.module.scss';
import download_icon from './assets/download_icon.svg'

class Model extends React.Component {
	handleClick() {
		window.location.href = this.props.model.downloadURL();
	}

  render() {
    return (
      <div className={style.Model}
      		 onClick={this.handleClick.bind(this)}>
      	<div className={style.ModelContainer}>
      		<div className={style.ModelTitle}>
      			{this.props.model.title()}
      		</div>
      		<div className={style.ModelSize}>
      			{this.props.model.size()}
      		</div>
      	</div>
      	<div className={style.Description}>
      		{this.props.model.description()}
      	</div>
      	<div className={style.DownloadIcon}>
      		<img src={download_icon} />
      	</div>
      </div>
    );
  }
}

export default Model;